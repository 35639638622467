import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer } from '../components/footer';

import './index.scss';

export default class Layout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render = () => (
    <>
      <Helmet>
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="icon" type="image/png" href="/favicon.png" />
      </Helmet>
      { this.props.children }
      <Footer />
    </>
  )
}