import React from 'react';
import classNames from 'classnames';
import Up from './up.inline.svg';
import Logo from '../header/logo.inline.svg';

export class Footer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'footer': true
    });
  }

  top = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render = () => (
    <footer className={this.className}>
      <a href="#top" className="footer__up" onClick={this.top}><Up/></a>
      <div className="footer__content">
        <div className="footer__content__left"><Logo /></div>
        <div className="footer__content__center">
          <a href="https://vimeo.com/user53349250" target="_blank">Vimeo</a>—
          <a href="https://www.facebook.com/LaCambreModeS" target="_blank">Facebook</a>—
          <a href="https://www.instagram.com/lacambremodes/" target="_blank">Instagram</a>
        </div>
        <div className="footer__content__right"><img src="/ensav.jpg" /></div>
      </div>
    </footer>
  )
}