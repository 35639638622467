// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-about-js": () => import("./../src/templates/about/about.js" /* webpackChunkName: "component---src-templates-about-about-js" */),
  "component---src-templates-collection-collection-js": () => import("./../src/templates/collection/collection.js" /* webpackChunkName: "component---src-templates-collection-collection-js" */),
  "component---src-templates-grade-grade-js": () => import("./../src/templates/grade/grade.js" /* webpackChunkName: "component---src-templates-grade-grade-js" */),
  "component---src-templates-homepage-homepage-js": () => import("./../src/templates/homepage/homepage.js" /* webpackChunkName: "component---src-templates-homepage-homepage-js" */),
  "component---src-templates-show-show-js": () => import("./../src/templates/show/show.js" /* webpackChunkName: "component---src-templates-show-show-js" */)
}

